// const React = require('react')
// const Layout = require('./src/components/Layout')

import React from 'react'
import Provider from './src/components/Provider'
import { ThemeProvider } from './src/components/ThemeContext'
// import Layout from './src/components/Layout'

export const wrapRootElement = ({ element }) => (
    <>
     {/* <Layout {...props}> */}
        <Provider>
            <ThemeProvider>
                {element}
            </ThemeProvider>
        </Provider>
    {/* </Layout> */}
    </>
  );

// exports.wrapPageElement = ({ element, props }) => {
//   return <Layout {...props}>{element}</Layout>
// }

// import React from "react"
// // import { SubscriptionProvider } from "./src/context/SubscriptionContext"
// // import { SettingsProvider } from "./src/context/SettingsContext"
// import Layout from './src/components/Layout'
// // const Layout = require('./src/components/Layout').default
// import { ThemeProvider } from './src/components/ThemeContext'
// import Provider from './src/components/Provider'
// import { Context } from './src/components/Context'
// import { IntlProvider, FormattedMessage } from 'react-intl'

// export const wrapPageElement = ({ element, props }) => {
    
//   return (
//     <Layout {...props}>
//       <Provider>
//        <ThemeProvider>
//           {element}
//         </ThemeProvider>
//       </Provider>
//     </Layout>
//   )
// }


// exports.wrapRootElement = ({ element, props }) => {
//     return <Layout {...props}>{element}</Layout>
//   }