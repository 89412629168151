import React, { useState, useEffect, useMemo } from 'react'

import {
  COLORS,
  COLOR_MODE_KEY,
  INITIAL_COLOR_MODE_CSS_PROP
} from '../constants'

// const defaultState = {
//   dark: false,
//   toggleDark: () => {},
// }

export const ThemeContext = React.createContext()

const ThemeProvider = ({ children }) => {
  const [colorMode, rawSetColorMode] = React.useState(undefined);
  const [settings, setSettings] = useState([])

  useEffect(() => {
    const root = window.document.documentElement;

    // Because colors matter so much for the initial page view, we're
    // doing a lot of the work in gatsby-ssr. That way it can happen before
    // the React component tree mounts.

    const initialColorValue = root.style.getPropertyValue(
      INITIAL_COLOR_MODE_CSS_PROP
    )

    rawSetColorMode(initialColorValue)
  }, [])

  const contextValue = useMemo(() => {
    function setColorMode (newValue) {
      const root = window.document.documentElement

      let storage
      // if (typeof window !== 'undefined') {
        if (localStorage.getItem("dcfz-settings") !== null) {
          storage = localStorage.getItem("dcfz-settings");
          setSettings(JSON.parse(storage))
        } else {
          // localStorage.setItem("caluba-settings", JSON.stringify(settings) )
        }
      // }

      let settingsCopy = [...settings]
      // create a copy of our settings state, avoid overwritting existing state
      if (Array.isArray(settings) && settings.length) {
        const settingsCopy = []
      }
      else {
        if (storage) {
          settingsCopy = JSON.parse(storage)
        } else {
          settingsCopy = [...settings]
        }
      }

      let item

      let copy

      if(storage) {
        copy = JSON.parse(storage)
      } else copy = null


      if (copy === null) {
        item = {
          consent: 'yes',
          lang: copy.map(item => item.lang).toString(),
          colorMode: newValue,
          advert: copy.map(item => item.advert).toString()
          // tileView: Boolean(copy.map(item => item.tileView))
        }


        // settingsCopy.push(item)
        settingsCopy = [item]
        // cartCopy = [...cart, item]

        // update app state
        setSettings(settingsCopy)

        // make cart a string and store in local space
        let stringifiedSettings = JSON.stringify(settingsCopy);
        localStorage.setItem("dcfz-settings", stringifiedSettings)
      }


      if (copy) {
        item = {
          consent: 'yes',
          lang: copy.map(item => item.lang).toString(),
          colorMode: newValue,
          advert: copy.map(item => item.advert).toString()
        }


        // settingsCopy.push(item)
        settingsCopy = [item]
        // cartCopy = [...cart, item]

        // update app state
        setSettings(settingsCopy)

        // make cart a string and store in local space
        let stringifiedSettings = JSON.stringify(settingsCopy);
        localStorage.setItem("dcfz-settings", stringifiedSettings)
      }


      Object.entries(COLORS).forEach(([name, colorByTheme]) => {
        const cssVarName = `--color-${name}`;

        root.style.setProperty(cssVarName, colorByTheme[newValue]);
      });

      rawSetColorMode(newValue);
    }

    return {
      colorMode,
      setColorMode,
    }
  }, [colorMode, rawSetColorMode]);

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  )
}

// export default ThemeContext

export { ThemeProvider }
