import React from 'react'
import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { Context } from './Context'

const LangProvider = ({ children, lang, toggleLanguage, switchToGerman, switchToEnglish, switchToSpanish }) => (
  <Context.Provider value={
    {
      lang,
      toggleLanguage: () => toggleLanguage(),
      switchToGerman: () => switchToGerman(),
      switchToEnglish: () => switchToEnglish(),
      switchToSpanish: () => switchToSpanish()
    }
  }>
    {children}
  </Context.Provider>
)

const enhance = compose(
  withState('lang', 'handleLanguage', 'en'),
  withHandlers({
    // toggleLanguage: ({ lang, handleLanguage }) => () => {
    //   if (lang === 'de') {
    //     handleLanguage('en')
    //
    //     let storage
    //     let settingsCopy = []
    //
    //     if (localStorage.getItem("dcfz-settings") !== null) {
    //       storage = localStorage.getItem("dcfz-settings");
    //       settingsCopy = JSON.parse(storage)
    //     } else {
    //       // localStorage.setItem("caluba-settings", JSON.stringify(settings) )
    //     }
    //
    //     const item = {
    //       consent: 'yes',
    //       // colorMode: colorMode,
    //       lang: 'de'
    //     }
    //
    //     settingsCopy = [item]
    //
    //     const stringifiedSettings = JSON.stringify(settingsCopy)
    //     localStorage.setItem("dcfz-settings", stringifiedSettings)
    //
    //   }
    //   else {
    //     handleLanguage('de')
    //     // localStorage.setItem('lang', 'de')
    //     let storage
    //     let settingsCopy = []
    //
    //     if (localStorage.getItem("dcfz-settings") !== null) {
    //       storage = localStorage.getItem("dcfz-settings");
    //       settingsCopy = JSON.parse(storage)
    //     } else {
    //       // localStorage.setItem("caluba-settings", JSON.stringify(settings) )
    //     }
    //
    //     const item = {
    //       consent: 'yes',
    //       // colorMode: colorMode,
    //       lang: 'de'
    //     }
    //
    //     settingsCopy = [item]
    //
    //     const stringifiedSettings = JSON.stringify(settingsCopy)
    //     localStorage.setItem("dcfz-settings", stringifiedSettings)
    //   }
    // },

    switchToGerman: ({ lang, handleLanguage }) => () => {
      handleLanguage('de')

      let storage
      let settingsCopy = []

      if (localStorage.getItem("dcfz-settings") !== null) {
        storage = localStorage.getItem("dcfz-settings");
        settingsCopy = JSON.parse(storage)
      } else {
        // localStorage.setItem("caluba-settings", JSON.stringify(settings) )
      }

      const item = {
        consent: 'yes',
        advert: settingsCopy.map(item => item.advert).toString(),
        colorMode: settingsCopy.map(item => item.colorMode).toString(),
        lang: 'de'
      }

      settingsCopy = [item]

      const stringifiedSettings = JSON.stringify(settingsCopy)
      localStorage.setItem("dcfz-settings", stringifiedSettings)
      // localStorage.setItem('lang', 'de')
    },
    switchToEnglish: ({ lang, handleLanguage }) => () => {
      handleLanguage('en')

      let storage
      let settingsCopy = []

      if (localStorage.getItem("dcfz-settings") !== null) {
        storage = localStorage.getItem("dcfz-settings");
        settingsCopy = JSON.parse(storage)
      } else {
        // localStorage.setItem("caluba-settings", JSON.stringify(settings) )
      }

      const item = {
        consent: 'yes',
        advert: settingsCopy.map(item => item.advert).toString(),
        colorMode: settingsCopy.map(item => item.colorMode).toString(),
        lang: 'en'
      }

      settingsCopy = [item]

      const stringifiedSettings = JSON.stringify(settingsCopy)
      localStorage.setItem("dcfz-settings", stringifiedSettings)
      // localStorage.setItem('lang', 'en')
    },
    switchToSpanish: ({ lang, handleLanguage }) => () => {
      handleLanguage('es')
      let storage
      let settingsCopy = []

      if (localStorage.getItem("dcfz-settings") !== null) {
        storage = localStorage.getItem("dcfz-settings");
        settingsCopy = JSON.parse(storage)
      } else {
        // localStorage.setItem("caluba-settings", JSON.stringify(settings) )
      }

      const item = {
        consent: 'yes',
        advert: settingsCopy.map(item => item.advert).toString(),
        colorMode: settingsCopy.map(item => item.colorMode).toString(),
        lang: 'es'
      }

      settingsCopy = [item]

      const stringifiedSettings = JSON.stringify(settingsCopy)
      localStorage.setItem("dcfz-settings", stringifiedSettings)
      // localStorage.setItem('lang', 'es')
    }
  }),
  lifecycle({
    componentDidMount () {
      let storage
      let localLang

      storage = localStorage.getItem("dcfz-settings");
      const settings = JSON.parse(storage)

      if (settings && settings.length) {
        localLang = settings.map(item => item.lang).toString()
      }

      // const localLang = localStorage.getItem('lang')
      if (localLang) {
        this.props.handleLanguage(localLang)
      } else {
        this.props.handleLanguage(navigator.language.split('-')[0])
      }
    }
  })
)

export default enhance(LangProvider)
