export const COLORS = {
  text: {
    light: 'hsl(0deg, 0%, 10%)', // white
    dark: 'hsl(0deg, 0%, 100%)' // black
  },
  background: {
    light: 'hsl(0deg, 0%, 95%)', // white
    // dark: 'hsl(0, 100%, 0%, 1)' // black
    // dark: 'rgba(21,20,27,1)',
    dark: 'rgba(14,23,35,1)',
    // dark: '#102240'
  },
  border: {
    light: 'rgba(14,23,35,1)', // white
    // dark: 'hsl(0, 100%, 0%, 1)' // black
    // dark: 'rgba(21,20,27,1)',
    dark: 'hsl(0deg, 0%, 100%)',
    // dark: '#102240'
  },
  darkBgText: {
    light: 'hsl(0deg, 0%, 100%)',
    dark: '#6D86A1',
  },
  darkBgHeading: {
    light: 'hsl(0deg, 0%, 100%)',
    dark: '#6D86A1'
  },
  primary: {
    // light: '#85E9CA',
    light: 'rgba(61,234,169)',
    // light: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,121,60,1) 35%, rgba(0,212,255,1) 100%)',
    dark: 'rgba(14,23,35,1)'
  },
  primaryBgText: {
    // light: '#000000E6',
    light: 'hsl(0deg, 0%, 10%)',
    dark: '#6D86A1'
  },
  lightBg: {
    light: '#fff',
    dark: 'rgba(14,23,35,1)',
  },
  // Grays, scaling from least-noticeable to most-noticeable
  gray300: {
    light: '#6D86A1',
    dark: 'rgba(0,0,0,0.1)',
  },
  gray500: {
    light: 'hsl(0deg, 0%, 50%)',
    dark: 'hsl(0deg, 0%, 50%)'
  },
  gray700: {
    light: 'hsl(0deg, 0%, 30%)',
    dark: 'hsl(0deg, 0%, 70%)'
  }
}

// export const COLOR_MODE_KEY = 'color-mode';
export const COLOR_MODE_KEY = 'colorMode'
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode'
// export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-colorMode';
