import React from 'react'
// import localEN from '../../intl/en.json'
// import localDE from '../../intl/de.json'


export const Context = React.createContext('en')


// export const Context = () => {
// const [language, setLanguage] = useState(localDE)

// React.createContext({
//     lang: language,
//     setLanguage: () => {}
//   });

// }


// export const Context = React.createContext({
//     lang: 'es',
//     setLanguage: () => {}
//   });